<template>
  <b-carousel
    id="carousel-1"
    v-model="slide"
    :interval="5000"
    indicators
    style="text-shadow: 1px 1px 2px #333"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
    class="hero-banner-carousel"
  >
    <b-carousel-slide
      v-for="(bSlide, index) in slider.slides"
      :key="index"
      :img-src="bSlide.optImage"
    >
      <template v-slot:img>
        <b-link :to="bSlide.link" class="ml-md-0">
          <img
            class="d-block img-fluid w-100"
            :src="bSlide.optImage"
            :alt="bSlide.title"
          />
          <div class="carousel-caption">
            <div class="container">
              <div
                class="carousel-caption"
                v-html="bSlide.description"
                v-if="bSlide.description"
              ></div>
              <b-link
                :to="bSlide.link"
                v-if="bSlide.buttonText"
                class="btn btn-trans-white ml-md-0"
                >{{ bSlide.buttonText }}</b-link
              >
            </div>
          </div>
        </b-link>
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import { isTablet } from "mobile-device-detect";
import { isBrowser } from "mobile-device-detect";

export default {
  name: "HeroBanner",
  data: () => ({
    slide: 0,
    sliding: false,
  }),
  computed: {
    slider() {
      let slider = this.$store.getters["sliders/getSliderByIdentifier"]("home");

      if (slider.slides != null) {
        slider.slides.forEach((element) => {
          if (this.mobileCheck == "browser") {
            element.optImage = element.media.url;
          } else if (this.mobileCheck == "tablet") {
            element.optImage = element.media.larger;
          } else {
            element.optImage = element.media.medium;
          }
        });
      }
      return slider;
    },
    mobileCheck() {
      if (isBrowser) {
        return "browser";
      }
      if (isMobile && isTablet) {
        return "tablet";
      }
      return "mobile";
    },
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>
